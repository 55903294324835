import React, { useState } from 'react';
import { FormControl, OutlinedInput, Box, Typography, Button } from '@mui/material'

const FormGenerator = ({ handleSubmit,fields, closeModal }) => {
    const [formData, setFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        let errors = {};
        let isValid = true;

        fields.forEach((field) => {
            if (field.required && !formData[field.name]) {
                errors[field.name] = `${field.label} is required`;
                isValid = false;
            }

            if (field.type === 'email' && formData[field.name] && !/\S+@\S+\.\S+/.test(formData[field.name])) {
                errors[field.name] = `${field.label} is invalid`;
                isValid = false;
            }
        });

        setFormErrors(errors);
        return isValid;
    };

    const submitData = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Submit the form
            handleSubmit(formData)
            console.log('Form submitted successfully:', formData);
        } else {
            console.log('Form has errors');
        }
    };

    return (
        <form onSubmit={submitData}>
            {fields.map((field) => {
                if (field.type === 'select') {
                    return (
                        <div key={field.name}>
                            <label>{field.label}:</label>
                            <select
                                name={field.name}
                                value={formData[field.name] || ''}
                                onChange={handleInputChange}
                            >
                                <option value="">Select an option</option>
                                {field.options.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            {formErrors[field.name] && <div className="error">{formErrors[field.name]}</div>}
                        </div>
                    );
                }

                return (
                    <div key={field.name}>
                        <Box sx={{ p: 2 }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography>
                                    {field.label}
                                    {!field.optional && <span style={{ color: 'red' }}>&nbsp;*</span>}
                                </Typography>
                                <div style={{ flexGrow: 1 }}></div>
                            </div>
                            <FormControl sx={{ mt: 1, width: '100%' }} size='small'>
                                <OutlinedInput
                                    id={field.name}
                                    size='small'
                                    disabled={false}
                                    error={formErrors[field.name]}
                                    type={field.type}
                                    placeholder={field.label}
                                    multiline={!!field.rows}
                                    rows={field.rows ?? 1}
                                    value={formData[field.name] || ''}
                                    name={field.name}
                                    onChange={handleInputChange}
                                    inputProps={{
                                        style: {
                                            height: field.rows ? '90px' : 'inherit'
                                        }
                                    }}
                                />
                            </FormControl>

                        </Box>

                    </div>
                );
            })}
            <div style={{display:"flex",justifyContent:"flex-end"}}>
                <Button sx={{ borderRadius: 25, width: '30%', mb: 2,mr:2,minWidth:"150px",backgroundColor:"#2196f3 !important"}} variant='contained' type="submit">Submit</Button>
                <Button sx={{ borderRadius: 25, width: '30%', mb: 2,minWidth:"150px" }} variant='outlined' onClick={closeModal}>Close</Button>
            </div>
        </form>
    );
};

export default FormGenerator;