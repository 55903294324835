// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAE2oUQzVBnHBSZEkeGgJbqzAUekiKCLds",
  authDomain: "toolchain-dc326.firebaseapp.com",
  databaseURL: "https://toolchain-dc326-default-rtdb.firebaseio.com",
  projectId: "toolchain-dc326",
  storageBucket: "toolchain-dc326.appspot.com",
  messagingSenderId: "757798473459",
  appId: "1:757798473459:web:89e73305bbc9de350e7186"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;