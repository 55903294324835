import React, {useState, useEffect} from 'react';
// import Sidebar from './components/widgets/Sidebar';

import { useSelector } from 'react-redux'
import Chat from './page/Chat';


import {Routes, Route} from 'react-router-dom';
// import Navbar from './components/widgets/Navbar';
import Layout from './components/widgets/Layout';
import { BrowserRouter as Router } from 'react-router-dom';


import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline, StyledEngineProvider } from '@mui/material'
import themes from './themes'
function App() {
  const customization = useSelector((state) => state.customization)

  return (
    <StyledEngineProvider injectFirst>
    <ThemeProvider theme={themes(customization)}>
    <CssBaseline />
      
    <Router>
      <div className="App bg-primary">
        <section>          
          <div>            
            <Routes>

                      
              
              <Route path="/" element={<Chat/>}/>
              
            </Routes>          
          </div>
        </section>

      </div>
    </Router>
    </ThemeProvider>
  </StyledEngineProvider>
  );
}

export default App;



