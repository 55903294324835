import { combineReducers } from 'redux'
import customizationReducer from './reducers/customizationReducer'
// reducer i

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
})

export default reducer
