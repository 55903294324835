import React, { useEffect, useState, useRef } from 'react';
import { Widget, addResponseMessage, deleteMessages, renderCustomComponent, toggleInputDisabled, addUserMessage, toggleMsgLoader, addLinkSnippet } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import { useSearchParams } from 'react-router-dom';
import FormGenerator from '../components/formGenerater';
import { Dialog, DialogActions, DialogContent, OutlinedInput, DialogTitle, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Text from '../components/elements/Text';
const Demo = () => {
    const [showModal, setShowModal] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [url, setUrl] = useState("https://cdpuzmsd2wgdgoj23rvci7plmq0sesic.lambda-url.us-east-1.on.aws/conversations/")
    const [domain, setDomain] = useState(document.referrer)
    const [chatId, setChatId] = useState(searchParams.get('chatId'))
    const [userId, setUserId] = useState(searchParams.get('userId'))
    // const [chatAgent, setChatAgent] = useState(searchParams.get('chatAgent'))
    const [profileIcon, setProfileIcon] = useState("https://media.istockphoto.com/id/1256529118/vector/chat-bot-using-laptop-computer-robot-virtual-assistance-of-website-or-mobile-applications.jpg?s=612x612&w=0&k=20&c=F6sQrcR8ZRdJJ54AXa8XYBiDtYj_bl0CkRtv3QPncCM=")
    const [titleIcon, setTitleIcon] = useState("https://img.freepik.com/free-vector/illustration-user-avatar-icon_53876-5907.jpg?w=2000")
    const [placeHolder, setPlaceHolder] = useState("Type a message...");
    const [showWidget, setShowWidget] = useState(false);
    const [toggleState, setToggleState] = useState(false);
    const [fields,setFields] = useState([])
    const [firstToggle, setFirstToggle] = useState(true);
    const [messageSent, setMessageSent] = useState(false)
    const [isTyping, setIsTyping] = useState(false)
    const [isTypingFirst, setIsTypingFirst] = useState(true)
    const [roomId, setRoomId] = useState(localStorage.getItem("roomId", ""));
    const [message,setCurrentMessage] = useState("")
    const wsRef = useRef(null);

    // const get_connect_clients_by_name = async (roomId, name) => {
    //     var res = await fetch(`https://api.shopagent.ai/api/room/${roomId}/clients-connected`)
    //     res = await res.json()
    //     var data = res.data || [];
    //     var agent_connected = data.filter(d => d.name == name)
    //     return agent_connected
    // }
    useEffect(() => {
        // console.log('isTyping:', isTyping);
        if (!isTyping && !isTypingFirst) {
            toggleMsgLoader();
        }
    }, [isTyping]);
    // Add this function to handle the state update and callback execution
    const setIsTypingAndUpdate = (value, callback) => {
        setIsTyping(value);
        callback();
    };

    // Add this function to execute the code that depends on the isTyping state update
    const afterIsTypingUpdated = () => {
        toggleMsgLoader();
    };
    useEffect(() => {
        document.body.classList.add('dark');
        if (roomId) {
            const subdomain = window.location.hostname.split('.')[0];
            const webSocketURL = `wss://${subdomain}.azule.ai/api/room/${roomId}/websocket`;
            wsRef.current = new WebSocket(webSocketURL);

            wsRef.current.onopen = async () => {
                wsRef.current.send(JSON.stringify({ name: "livechat" }));

            };

            wsRef.current.onmessage = async (event) => {

                const messageData = JSON.parse(event.data);
                const message = messageData.message;
                // console.log(messageData)
                if (messageData.actions && !messageData.blockedMessage) {
                    const data = {
                        'actions': messageData.actions
                    }
                    window.parent.postMessage(data, '*');
                }
                if(messageData.fields && !messageData.blockedMessage ){
                    setCurrentMessage(messageData.message)
                    setFields(messageData.fields)
                    setShowModal(true)
                }

                if (message && messageData.name !== "livechat") {
                    setIsTyping(false)
                    addResponseMessage(message);
                    // console.log(isTyping)

                }
                else if (message) {
                    if (messageSent) {
                        setMessageSent(false)
                    }
                    else {
                        addUserMessage(message)

                    }
                }
            };

            wsRef.current.onclose = () => {
            };
        }

        return () => {
            if (wsRef.current) {
                wsRef.current.close();
            }
        };
    }, [roomId]);

    useEffect(() => {
        addResponseMessage("Hello! How can I help you?");
        setShowWidget(true);
    }, []);
    useEffect(() => {
        if (!showWidget) {
            const data = {
                chatAction: 'hide'
            };
            window.parent.postMessage(data, '*');
        }
        else {
            const data = {
                chatAction: 'show'
            };
            window.parent.postMessage(data, '*');
        }
    }, [showWidget])

    function handleToggle() {
        setToggleState(!toggleState)
        if (firstToggle) {
            setFirstToggle(false);
            if (!roomId) {
                const subdomain = window.location.hostname.split('.')[0];
                fetch(`https://${subdomain}.azule.ai/api/room`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        agentId: chatId,
                    }),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        setRoomId(data); // Set roomId state with the received ID
                        localStorage.setItem("roomId", data)
                    })
                    .catch((error) => {
                        console.error("POST request error:", error);
                    });
            }
        }

        const data = {
            chatAction: "toggle",
        };
        window.parent.postMessage(data, "*");
    }

    const waitForRoomId = () => {
        return new Promise((resolve) => {
            const checkRoomId = () => {
                const roomId = localStorage.getItem("roomId");
                if (roomId) {
                    resolve();
                } else {
                    setTimeout(checkRoomId, 100);
                }
            };
            checkRoomId();
        });
    };
    const waitForWebSocket = () => {
        return new Promise((resolve) => {
            const checkWebSocket = () => {
                if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                    resolve();
                } else {
                    const subdomain = window.location.hostname.split('.')[0];
                    const webSocketURL = `wss://${subdomain}azule.ai/api/room/${roomId}/websocket`;
                    wsRef.current = new WebSocket(webSocketURL);
                    // setTimeout(checkWebSocket, 100);

                }
            };
            checkWebSocket();
        });
    };

    const handleNewUserMessage = async (newMessage) => {
        setIsTypingAndUpdate(true, afterIsTypingUpdated);
        setIsTypingFirst(false);

        var body = JSON.stringify({
            "message": newMessage,
        });
        // await waitForWebSocket()

        wsRef.current.send(body);
        setMessageSent(true);
        deleteMessages(1);
    };
    const handleSubmit = async (data)=>{
        var body = JSON.stringify({form_data:data,message:""})
        wsRef.current.send(body);
        setShowModal(true)

    }


    return (
        <>
            <div>
                {showWidget &&
                    <Widget
                        handleNewUserMessage={handleNewUserMessage}
                        title=""
                        subtitle=""
                        // subtitle={process.env.REACT_APP_NAME}
                        profileAvatar={profileIcon}
                        profileClientAvatar={titleIcon}
                        handleToggle={handleToggle}
                        emojis={true}
                        showBadge={false}
                        showCloseButton={true}
                        senderPlaceHolder={placeHolder}
                    />
                }
                <Dialog
                    open={toggleState? showModal:false}
                    fullWidth
                    // maxWidth='xs'
                    onClose={() => { setShowModal(false) }}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                    PaperProps={{ sx: { borderRadius: "0.5rem" } }}
                >
                    <DialogTitle sx={{ fontSize: '1rem', fontWeight: "bold" }} id='alert-dialog-title'>
                        {"Information required"}
                        <IconButton
                            aria-label="close"
                            onClick={()=>setShowModal(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <p>{message}</p>
                        <FormGenerator handleSubmit={handleSubmit} fields={fields} closeModal={() => { setShowModal(false) }}></FormGenerator>
                    </DialogContent>

                </Dialog>


            </div>
        </>
    )
}
export default Demo;